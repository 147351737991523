<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="warehouse.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId, organization) => {
            warehouse.organization.id = organizationId;
            warehouse.allowedLocations = [];
            warehouse.taxes = organization.taxes;
            onFormChanged();
          }
        "
        :disabled="!!warehouse.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="warehouse.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="warehouse.organization.id"
        @locationsChanged="
          (locations) => {
            warehouse.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-checkbox
      v-model="warehouse.is_model"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("WAREHOUSES.WAREHOUSE_IS_MODEL") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.is_model" />

    <template v-if="!warehouse.is_model">
      <base-input
        :label="`${$t('WAREHOUSES.WAREHOUSE_MODEL_USED')} (*)`"
        :placeholder="$t('WAREHOUSES.WAREHOUSE_MODEL_USED')"
        @change="onFormChanged"
      >
        <warehouse-selector
          :allowNone="true"
          :multiple="false"
          :showAll="false"
          :warehouse="warehouse.modelUsed ? warehouse.modelUsed.id : null"
          :isOnlyModels="true"
          @warehouseChanged="
            (warehouseId, modelSelected) => {
              if (warehouse.modelUsed) {
                warehouse.modelUsed.id = warehouseId;
              } else {
                warehouse.modelUsed = { type: 'warehouses', id: warehouseId };
              }
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.modelUsed" />
    </template>

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="warehouse.name"
      @change="onFormChanged"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-checkbox
      v-if="!warehouse.modelUsed?.id"
      v-model="warehouse.allow_negative_inventory"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("WAREHOUSES.ALLOW_NEGATIVE_INVENTORY") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.allow_negative_inventory" />

    <base-checkbox
      v-if="!warehouse.modelUsed?.id"
      v-model="warehouse.allow_unregistered_products"
      class="mb-3"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <span class="form-control-label">
        {{ $t("WAREHOUSES.ALLOW_UNREGISTERED_PRODUCTS") }}
      </span>
    </base-checkbox>
    <validation-error
      :errors="apiValidationErrors.allow_unregistered_products"
    />

    <base-checkbox v-model="warehouse.use_warehouse_taxes" class="mb-3">
      <span class="form-control-label">
        {{ $t("WAREHOUSES.USE_WAREHOUSE_TAXES") }}
      </span>
    </base-checkbox>
    <validation-error :errors="apiValidationErrors.use_warehouse_taxes" />

    <base-input
      :label="`${$t('COMMON.TAX_GROUPS')}`"
      :placeholder="$t('COMMON.TAX_GROUPS')"
      v-if="!warehouse.modelUsed?.id && warehouse.use_warehouse_taxes"
    >
      <tax-groups-selector
        :taxGroups="warehouse.taxGroups"
        @taxGroupsChanged="
          (taxGroups) => {
            warehouse.taxGroups = taxGroups;
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.taxGroups" />

    <!-- Excerpt -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.EXCERPT')}`"
          :placeholder="$t('COMMON.EXCERPT')"
        >
          <html-editor v-model="warehouse.excerpt" @change="onFormChanged()">
          </html-editor>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </div>
    </div>

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          warehouse.id
            ? $t("WAREHOUSES.EDIT_WAREHOUSE")
            : $t("WAREHOUSES.ADD_WAREHOUSE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import WarehouseSelector from "@/components/WarehouseSelector.vue";
import TaxGroupsSelector from "@/components/TaxGroupsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    WarehouseSelector,
    LocationsSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    TaxGroupsSelector,
  },

  mixins: [formMixin],

  props: ["warehouseData", "formErrors", "loading"],

  data() {
    let warehouseData = { ...this.warehouseData };
    warehouseData = this.$fillUserOrganizationData(warehouseData);
    return {
      warehouse: warehouseData,
      warehouseSelected: null,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      if (this.warehouse.is_model) {
        delete this.warehouse.modelUsed;
      }
      let warehouseData = cloneDeep(this.warehouse);

      if (!warehouseData?.modelUsed?.id) {
        warehouseData.modelUsed = null;
      }
      warehouseData = this.$fillUserOrganizationData(warehouseData);
      this.$emit("warehouseSubmitted", warehouseData);
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    warehouseData(warehouseData) {
      if (warehouseData) {
        this.warehouse = {
          ...this.warehouse,
          ...cloneDeep(warehouseData),
        };
      }
    },
  },
};
</script>
